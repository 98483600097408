import * as React from "react"
import Header from "../components/header-mentor"
import Footer from "../components/footer"
import imgProfile from "../images/leandro-cv.png"
import logoGreen from "../images/logo-green.png"
import ContactForm from "../components/contact-form"
import GDPRConsent from "../components/gdpr-consent"
import Seo from "../components/seo"
import PackagekOffer from "../components/package-block/leandro"
import { Helmet } from "react-helmet"
import DiscountBanners from "../components/discount-banners"

function coachingCurriculum() {
  return (
    <div className="text-left px-10 py-0">

      <b>Estrategias de Trading, técnicas de Mentoría Personal</b>
      <ul className="basic-list ml-14 mb-4">
        <li>Lleva tu trading a otro nivel.</li>
        <li>Con mi proceso de mentoría de trading y psicotrading,  le permitirá tener las herramientas y conocimientos necesarios para operar los mercados con seguridad y consistencia.</li>
        <li>Con mi proceso, operarás y pensarás con un profesional.</li>
      </ul>

      <b>Perfeccione su estrategia o aprenda mi plan de trading, adaptando la búsqueda de oportunidades s sus necesidades.</b>
      <ul className="basic-list ml-14 mb-4">
        <li>
          Análisis del mercado, correción de operativa, operaciones potenciales
        </li>
        <li>
          Trading en tendencia
        </li>
        <li>
          Todo gira en torno a una perfecta gestión del control de riesgos
        </li>
        <li>
          Mentor Profesional de Trading, cuestiones clave
        </li>
        <li>
          Experiencia en los mercados
        </li>
        <li>
          Cómo le convierte en un trader rentables
        </li>
      </ul>
    </div>
  )
}

const IndexPage = () => {
  const mentor = {
    name: "Leandro Andrada Conde",
    firstName: "Leandro",
    twitter: "@leanco",
    headerText: "Si le interesa aprender a detectar mis patrones de tendencia, Visíteme para recibir asesoramiento individualizado sobre cómo tener ese control.",
    videoUrlCode: "_iTmYwcrfsY",
    lang: 'esp',
  }
  return (
    <>
      <Seo title='Trader Coach Leandro' />
      <Helmet
        link={[
          {
            rel: 'alternate',
            hreflang: 'en',
            href: 'https://trader-coach.com/leandro/',
          },
          {
            rel: 'alternate',
            hreflang: 'es',
            href: 'https://trader-coach.com/leandro-esp/',
          }
        ]}
      />
      <GDPRConsent />
      <Header props={mentor} />
      <main>
        <>
          {<DiscountBanners mentor='leandro' />}
          <div className="bg-gray-900 text-white pt-8" id="bio">
            <div className="lg:w-3/4 xl:w-2/4 mx-auto">
              <div className="flex items-end">
                <div className="">
                  <img
                    className="inline w-40 md:w-60 lg:w-80"
                  src={imgProfile}
                  alt={'Leandro'}
                />
                </div>
                <div className="px-2 md:pb-6">
                  <span className="pb-3 block text-green-400 text-3xl font-bold">{mentor.name}</span>
                  <span className="block text-white md:text-3xl font-bold">TraderFX profesional e independiente</span>
                </div>
              </div>

              <div className="text-justify px-6 pt-6">
                Las habilidades de Leandro, están centradas totalmente en el control de riesgo recompensa en los mercados, todo desarrollado, con su particular forma de ver la tendencia. Lleva gestionando su propio capital desde hace mas de 20 años.
                <br /><br />

                Sus habilidades de gestión de riesgos, son las que le han brindado la oportunidad de negociar durante todos estos años en los mercados.
                <br /> <br />

                <span className="font-bold">
                  Si desea recibir asesoramiento de Leandro y está interesado en aprender a detectar patrones gráficos en tendencia, con control de riesgo, basado en los niveles  de Fibonacci, le recomienda al menos dos sesiones de asesoramiento. Para obtener más información sobre gestión de riesgos en trading, es posible que desee reservar al menos dos sesiones (y posiblemente más) según su caso individual.
                </span>

              </div>

              <div className="text-center">
                <img
                  className="inline h-60 w-60 lg:w-80 lg:h-80"
                  src={logoGreen}
                  alt="logo"
                />

              </div>
            </div>
          </div>

          <div className="relative py-2 overflow-x-hidden bg-green-400 sm:py-2"></div>

          <div className="bg-gray-50 text-gray-900 py-8">
            <div className="block text-center text-xl font-bold px-2 md:text-xl">
              <div className="text-green-400 text-2xl">BOOK NOW</div>
              Buy 3 sessions of 1 hour and get $150 discount<br />
              <span className="text-green-400">OR</span><br />
              Buy 1 session of 1 hour and get 40% discount<br />
              <span className="text-xl md:text-lg font-normal">* applies one time only for your first 1 hour session</span>
            </div>
          </div>

          <div className="relative py-2 overflow-x-hidden bg-green-400 sm:py-2"></div>

          <div className="bg-gray-900 text-white">
            <div className="max-w-7xl mx-auto md:text-center pt-4 pb-8 px-4 sm:px-6 sm:pt-16 lg:pb-8 lg:pt-0 lg:px-8 lg:w-3/4 xl:w-2/4 mx-auto">
              <div className="text-3xl font-bold py-8 text-green-400">
                Coaching Curriculum
              </div>
              {coachingCurriculum()}
            </div>
          </div>

          <div className="relative py-2 overflow-x-hidden bg-green-400 sm:py-2"></div>

          <PackagekOffer />

          <div className="relative py-2 overflow-x-hidden bg-green-400 sm:py-2">
            <div className="max-w-7xl mx-auto md:text-center pt-4 pb-12 px-4 sm:px-6 sm:pt-16 lg:pb-6 lg:pt-0 lg:px-8 lg:w-3/4 xl:w-2/4 mx-auto">
              <div className="text-3xl font-bold mb-6 mt-6 text-white text-center">
                Get 40% off for your first 1 hour session using the coupon: <span className="text-red-600 text-4xl">40%OFF</span>
              </div>
              <div className="text-xl font-bold mt-6 text-center">
                <u>Billed at $120</u>.
              </div>
              <div className="font-normal text-lg">
                Standard price for 1 hour session $200.
              </div>

              <div className="mt-4 flex justify-center" id="booknow">
                <div className="inline-flex">
                  <a
                    href="https://TraderCoach.as.me/?appointmentType=58719818"
                    target="_blank" rel="noopener" rel="noreferrer"
                    // className="inline-flex items-center justify-center px-5 py-3 border border-transparent text-base font-medium text-white face-blue-bg face-blue-btn-hover"
                    className="rounded-full w-full flex items-center justify-center px-8 py-3 border border-transparent text-base font-bold text-white bg-gray-900 hover:text-green-400 md:py-4 md:text-lg md:px-10"
                  >
                    Buy 1 Session Now!
                  </a>
                </div>
              </div>

              <div className="font-normal text-lg mt-8">
                * Click on the "Redeem Coupon or Package" button to enter the coupon code.
              </div>
              <div className="mb-4"></div>
            </div>
          </div>

          <div className="relative max-w-7xl mx-auto pt-10 pb-12 px-0 lg:px-8 h-full" id="booknow">
            <section className="bg-gray-100 overflow-hidden" id="leandro">
              <iframe
                src="https://app.acuityscheduling.com/schedule.php?owner=30981395&appointmentType=58719818" title="Schedule Appointment" width="100%" height="800" frameBorder="0"></iframe>
                <script src="https://embed.acuityscheduling.com/js/embed.js" type="text/javascript"></script>
            </section>
          </div>

          <ContactForm props={{ name: mentor.firstName, twitter: mentor.twitter, lng: 'esp' }} />

        </>
      </main>
      <Footer />
    </>
  )
}

export default IndexPage
